/**
 * Handles auth actions on the API.
 */

import * as bcrypt from 'bcryptjs';
import * as jwt from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';

import { getEnvironmentVariable } from './environment';

/**
 * Hashes a password using bcrypt
 * @param password the password to hash
 */
export function hashPassword(password: string): string {
  return bcrypt.hashSync(password, 10);
}

/**
 * Compares a password against a hashed password
 * @param password The password to compare
 * @param hashedPassword The hashed password
 */
export function comparePasswords(password: string, hashedPassword: string): boolean {
  return bcrypt.compareSync(password, hashedPassword);
}

/**
 * Signs a JWT for the provided user
 * @param user The user to return a JWT for
 */
export const appJwtForUser = (user: JWTUser): string => {
  return jwt.sign(
    {
      id: user.id,
      email: user.email,
      customerId: user.customerId,
    },
    getEnvironmentVariable('APP_SECRET'),
    {
      expiresIn: process.env.JWT_EXPIRATION || '30m',
    }
  );
};

/**
 * Parses and verifies a JWT token from header string
 * @param header The header to verify
 */
export const verifyAuthHeader = (
  header?: string
): {
  error?: undefined | string;
  user: JWTUser | null;
} => {
  if (!header) {
    return {
      error: 'No header',
      user: null,
    };
  }

  const token = header.replace('Bearer ', '');

  try {
    const decoded = jwt.verify(token, getEnvironmentVariable('APP_SECRET')) as JWTUser;

    return {
      error: undefined,
      user: decoded,
    };
  } catch (e: any) {
    return {
      error: e.name,
      user: null,
    };
  }
};

/**
 * Parses but does not verify JWT from header string
 *  @param header The header to verify
 */
export const decodeAuthHeader = (header?: string): JWTUser | null => {
  if (!header) return null;
  const token = header.replace('Bearer ', '');

  try {
    return jwtDecode(token) as JWTUser | null;
  } catch (e) {
    return null;
  }
};

export interface JWTUser extends jwt.JwtPayload {
  id: string;
  email: string;
  customerId: string;
}
