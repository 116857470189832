type EnvironmentVariables =
  | 'KEYCENTRIX_BASE_URL'
  | 'KEYCENTRIX_PASSWORD'
  | 'KEYCENTRIX_USERNAME'
  | 'SENDGRID_API_KEY'
  | 'SENDGRID_FROM_EMAIL'
  | 'SENDGRID_BASE_URL'
  | 'SENDGRID_FORGOT_PASSWORD_TEMPLATE_ID'
  | 'SENDGRID_VERIFY_ACCOUNT_TEMPLATE_ID'
  | 'TOKEN_EXPIRATION_TIME'
  | 'TOKEN_BUFFER_TIME'
  | 'APP_SECRET';

export const getEnvironmentVariable = (key: EnvironmentVariables) => {
  const value = process.env[key];

  if (!value) {
    throw new Error(`Environment variable ${key} is not set`);
  }

  return value;
};
