import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';

export const logError = (error: ApolloError) => {
  if (process.env.NODE_ENV === 'development') {
    const { graphQLErrors } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        console.error(graphQLError);
      });
    } else {
      console.error(error);
    }
  }

  if (process.env.SENTRY_LOGGING === 'true') {
    try {
      Sentry.captureException(error);
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }
    }
  }
};
