import { useApolloClient } from '@apollo/client';
import { GetServerSidePropsContext } from 'next';
import { useCallback } from 'react';

import { LOGIN_TOKEN_KEY, REMEMBER_USER_KEY } from '../constants';
import { Routes } from '../enums/routes';
import { decodeAuthHeader, JWTUser, verifyAuthHeader } from '../services/auth';

import { PageProps } from './../types/PageProps';
import { cookies } from './cookies';

interface Auth {
  login: (token: string, rememberDevice?: boolean) => void;
  logout: () => Promise<void>;
  user: JWTUser | null;
}

export const useAuth = (): Auth => {
  const client = useApolloClient();
  const existingToken = cookies().get(LOGIN_TOKEN_KEY);
  const user = decodeAuthHeader(existingToken);

  /**
   * Logs in a user by setting an auth token in a cookie. We use cookies so they are available in SSR.
   * @param token the token to login with
   */
  const login = useCallback((token: string, rememberDevice = true) => {
    const options = {
      path: '/',
    };

    cookies().set(LOGIN_TOKEN_KEY, token, options);

    if (rememberDevice) {
      const user = decodeAuthHeader(token);

      if (user) {
        cookies().set(REMEMBER_USER_KEY, user.email, options);
      }
    } else {
      cookies().remove(REMEMBER_USER_KEY);
    }
  }, []);

  /**
   * Logs out a user by removing their token from cookies.
   */
  const logout = useCallback(async () => {
    cookies().remove(LOGIN_TOKEN_KEY, { path: '/' });
    await client.resetStore();
  }, [client]);

  return {
    login,
    logout,
    user,
  };
};

interface Options {
  /** A function that checks if the user can access the page */
  authorized: (user: JWTUser | null) => Promise<boolean> | boolean;
  /** Redirects to this page, defaults to root */
  redirectTo?: string;
}

type AuthRedirectReturn =
  | {
      redirect: Record<string, unknown>;
    }
  | {
      props: PageProps;
    };

export const authRedirect = async (
  ctx: GetServerSidePropsContext,
  options: Options
): Promise<AuthRedirectReturn> => {
  const { authorized, redirectTo = '/' } = options;
  const token = cookies(ctx).get(LOGIN_TOKEN_KEY);
  const { user, error } = verifyAuthHeader(token);
  const split = ctx.req.url?.split('?');
  const pathname = split?.[0] || '/';

  const isAuthorized = await authorized(user);

  if (!isAuthorized) {
    const destination =
      redirectTo === Routes.LOGIN
        ? `${Routes.LOGIN}?next=${encodeURIComponent(pathname)}`
        : redirectTo;

    return {
      redirect: {
        destination,
        permanent: false,
      },
    };
  }

  return {
    props: {
      user,
      authError: error || null,
    },
  };
};
