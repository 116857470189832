import { Checkbox, CheckboxProps, Flex, FlexProps, Text } from '@chakra-ui/react';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';

interface FormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends FlexProps {
  controllerProps: UseControllerProps<TFieldValues, TName>;
  checkboxProps?: CheckboxProps;
  text: string;
}

export const FormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: FormCheckboxProps<TFieldValues, TName>
) => {
  const { controllerProps, checkboxProps, text, ...rest } = props;
  const control = useController<TFieldValues, TName>(controllerProps);

  return (
    <Flex {...rest} alignItems="center">
      <Checkbox
        colorScheme="synchrony"
        defaultChecked={control.field.value}
        mr={2}
        {...control.field}
        {...checkboxProps}
      />
      <Text>{text}</Text>
    </Flex>
  );
};
