import { Box, Button, Flex, Stack, Text, useToast } from '@chakra-ui/react';
import { gql } from 'apollo-server-micro';
import { GetServerSidePropsContext } from 'next';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ErrorText } from '../components/form/ErrorText';
import { FormCheckbox } from '../components/form/FormCheckbox';
import { FormInput } from '../components/form/FormInput';
import { Page } from '../components/generic/Page';
import { PageContainer } from '../components/generic/PageContainer';
import { PageHeader } from '../components/generic/PageHeader';
import { LOGIN_TOKEN_KEY, REMEMBER_USER_KEY } from '../constants';
import { Routes } from '../enums/routes';
import { authRedirect, useAuth } from '../lib/auth';
import { cookies } from '../lib/cookies';
import { InputTypes } from '../options/inputOptions';
import { defaultToastOptions } from '../options/toastOptions';
import { logError } from '../services/logging';
import { useLoginMutation } from '../types';
import { PageProps } from '../types/PageProps';
import { setErrorsFromGraphQLErrors } from '../utils/setErrors';

interface FormFields {
  email: string;
  password: string;
  rememberDevice: boolean;
}

export default function LoginPage({ authError }: PageProps) {
  const router = useRouter();
  const rememberedEmail = cookies().get(REMEMBER_USER_KEY);

  const { handleSubmit, control, setError } = useForm<FormFields>({
    defaultValues: {
      email: rememberedEmail,
      rememberDevice: !!rememberedEmail,
    },
  });

  const [login, { error, loading }] = useLoginMutation();

  const { login: loginUser } = useAuth();
  const toast = useToast();

  useEffect(() => {
    if (authError === 'TokenExpiredError') {
      toast({
        ...defaultToastOptions.info,
        description: 'Your session has expired. Please login again.',
      });

      cookies().remove(LOGIN_TOKEN_KEY);
    }
  }, [authError, toast]);

  /**
   * Submits the login form
   * @param formData the data passed from the form hook
   */
  async function handleLogin(formData: FormFields) {
    try {
      const { data } = await login({
        variables: {
          email: formData.email,
          password: formData.password,
        },
      });

      if (data?.login?.token) {
        await loginUser(data.login.token, formData.rememberDevice);

        const { next } = router.query;

        if (typeof next === 'string') {
          await router.replace(decodeURIComponent(next));
        } else {
          await router.replace(Routes.HOME);
        }
      }
    } catch (error: any) {
      setErrorsFromGraphQLErrors(setError, error.graphQLErrors);
      logError(error);
    }
  }

  return (
    <Page hideNavigation position="relative" title="Login">
      <PageHeader color="white" title="Welcome to the Synchrony Rx@Home Portal" white />
      <PageContainer backgroundColor="white">
        <Box as="form" onSubmit={handleSubmit(handleLogin)} zIndex="overlay">
          <Stack spacing={4}>
            <FormInput
              controllerProps={{
                control,
                name: InputTypes.EMAIL,
              }}
            />
            <FormInput
              controllerProps={{
                control,
                name: InputTypes.PASSWORD,
              }}
            />
            {error && <ErrorText textAlign="left">{error.message}</ErrorText>}
          </Stack>

          <Flex justifyContent="flex-end" mt={2} width="full">
            <NextLink href={Routes.FORGOT_PASSWORD}>
              <Text textStyle="link">Reset Password</Text>
            </NextLink>
          </Flex>

          <FormCheckbox
            controllerProps={{ control, name: 'rememberDevice' }}
            mb={2}
            mt={4}
            text="Remember this device"
          />

          <Button
            isLoading={loading}
            onClick={handleSubmit(handleLogin)}
            type="submit"
            width="full"
          >
            Log In
          </Button>
        </Box>
      </PageContainer>

      <PageContainer>
        <NextLink href={Routes.REGISTRATION}>
          <Button type="button" variant="outline-white" width="full">
            New User - Register
          </Button>
        </NextLink>
      </PageContainer>

      <Box bottom={0} height="130vh" left={0} position="absolute" top={0} width="100vw" zIndex={-1}>
        <Box height="100%" position="relative" width="100%">
          <Image layout="fill" objectFit="cover" placeholder="blur" src="/LoginBackground.jpeg" />
        </Box>
      </Box>
    </Page>
  );
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) =>
  authRedirect(ctx, {
    authorized: (user) => !user,
    redirectTo: '/',
  });

gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;
