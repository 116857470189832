import { UseToastOptions, Status } from '@chakra-ui/react';

const sharedOptions: UseToastOptions = {
  isClosable: true,
  position: 'top',
};

export const defaultToastOptions: {
  [key in Status]: UseToastOptions;
} = {
  default: {},
  success: {
    ...sharedOptions,
    title: 'Success!',
    status: 'success',
  },
  error: {
    ...sharedOptions,
    description: 'Something went wrong.',
    title: 'Error',
    status: 'error',
  },
  info: {
    ...sharedOptions,
    title: 'Info',
    status: 'info',
  },
  warning: {
    ...sharedOptions,
    title: 'Warning',
    status: 'warning',
  },
};
