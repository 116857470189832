import { Text } from '@chakra-ui/react';

import { SynchronyLogo } from '../../images/SynchronyLogo';
import { SynchronyLogoWhite } from '../../images/SynchronyLogoWhite';

import { PageContainer } from './PageContainer';

/** Page header with logo and title */
export const PageHeader = ({
  title,
  color,
  white,
  mt,
}: {
  title?: string;
  color?: string;
  white?: boolean;
  mt?: number;
}) => {
  const Logo = white ? SynchronyLogoWhite : SynchronyLogo;
  return (
    <PageContainer direction="column" py={8} textAlign="center">
      <div>
        <Logo />
      </div>
      {title && (
        <Text as="h2" color={color ? color : undefined} mt={mt || 4} textStyle="h2">
          {title}
        </Text>
      )}
    </PageContainer>
  );
};
